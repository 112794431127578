import {createSlice} from '@reduxjs/toolkit';
const initialState = {
  random: null,
  randomUsers: null,
  dataModal: null,
  dataTriSoModal: null,
  dataDonDocModal: null,
  dataLichChiTietModal: null,
  modalVisible: false,
  modalDonDocVisible: false,
  dataSearch: null,
  modalDanhSachChuyenDiVisible: false,
  dataDanhSachChuyenDiSearch: null,
  dataTripModal: null,
  modalTripVisible: false,
  dataDatXe: null,
  modalDatXeVisible: false,
  modalCategoryAttributeVisible: false,
  modalAreaInfoVisible: false,
  modalPermissionVisible: false,
  modalPhanHoiVisible: false,
  dataPhanHoi: null,
  currentRole: null,
  currentOrganizationUnit: null,
  modalOrganizationUnit: null,
  listLoading: false,
  actionsLoading: false,
  error: null,
  dataListNhuanButCaNhan: [],
  modalDeleteVisible: false,
  hieuChinhNhuanButModalVisible: false,
  hieuChinhNhuanButDataModal: null,
  modalNguoiPhuThuocVisible: false,
  dataNguoiPhuThuocModal: null,
  appUserId: null,
  modalThongKeVisible: false,
  modalImportVisible: false,
  dataImportModal: null,
};

export const callTypes = {
  list: 'list',
  action: 'action',
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    setDataModal: (state, action) => {
      const payload = action.payload;
      state.dataModal = payload;
    },

    setDataTriSoModal: (state, action) => {
      const payload = action.payload;
      state.dataTriSoModal = payload;
    },

    setDataLichChiTietModal: (state, action) => {
      const payload = action.payload;
      state.dataLichChiTietModal = payload;
    },

    setDataDonDocModal: (state, action) => {
      const payload = action.payload;
      state.dataDonDocModal = payload;
    },

    setDataNghiemThuModal: (state, action) => {
      const payload = action.payload;
      state.dataNghiemThuModal = payload;
    },

    setCurrentRole: (state, action) => {
      const payload = action.payload;
      state.currentRole = payload;
    },

    setCurrentOrganizationUnit: (state, action) => {
      const payload = action.payload;
      state.currentOrganizationUnit = payload;
    },

    setModalOrganizationUnit: (state, action) => {
      const payload = action.payload;
      state.modalOrganizationUnit = payload;
      if (!state.modalOrganizationUnit) {
        state.currentOrganizationUnit = null;
      }
    },

    setDataTripModal: (state, action) => {
      const payload = action.payload;
      state.dataTripModal = payload;
    },

    setDataDatVe: (state, action) => {
      const payload = action.payload;
      state.dataDatVe = payload;
    },

    setModalVisible: (state, action) => {
      const payload = action.payload;
      state.modalVisible = payload;
      if (!state.modalVisible) {
        state.dataModal = null;
      }
    },

    setModalTriSoVisible: (state, action) => {
      const payload = action.payload;
      state.modalTriSoVisible = payload;
      if (!state.modalTriSoVisible) {
        state.dataTriSoModal = null;
      }
    },

    setModalLichChiTietVisible: (state, action) => {
      const payload = action.payload;
      state.modalLichChiTietVisible = payload;
      if (!state.modalLichChiTietVisible) {
        state.dataLichChiTietModal = null;
      }
    },

    setModalDonDocVisible: (state, action) => {
      const payload = action.payload;
      state.modalDonDocVisible = payload;
      if (!state.modalDonDocVisible) {
        state.dataDonDocModal = null;
      }
    },

    setModalNghiemThuVisible: (state, action) => {
      const payload = action.payload;
      state.modalNghiemThuVisible = payload;
      if (!state.modalNghiemThuVisible) {
        state.dataNghiemThuModal = null;
      }
    },

    setModalPermissionVisible: (state, action) => {
      const payload = action.payload;
      state.modalPermissionVisible = payload;
      if (!state.modalPermissionVisible) {
        state.dataModal = null;
      }
    },

    setModalTripVisible: (state, action) => {
      const payload = action.payload;
      state.modalTripVisible = payload;
      if (!state.modalTripVisible) {
        state.dataTripModal = null;
      }
    },

    setModalDatVeVisible: (state, action) => {
      const payload = action.payload;
      state.modalDatVeVisible = payload;
      if (!state.modalDatVeVisible) {
        state.dataDatVe = null;
      }
    },

    setModalAreaInfoVisible: (state, action) => {
      const payload = action.payload;
      state.modalAreaInfoVisible = payload;
      if (!state.modalAreaInfoVisible) {
        state.dataModal = null;
      }
    },

    setModalCategoryAttributeVisible: (state, action) => {
      const payload = action.payload;
      state.modalCategoryAttributeVisible = payload;
      if (!state.modalCategoryAttributeVisible) {
        state.dataModal = null;
      }
    },

    setModalPhanHoiVisible: (state, action) => {
      const payload = action.payload;
      state.modalPhanHoiVisible = payload;
      if (!state.modalPhanHoiVisible) {
        state.dataPhanHoi = null;
      }
    },
    setDataPhanHoi: (state, action) => {
      const payload = action.payload;
      state.dataPhanHoi = payload;
    },
    setModalNguoiPhuThuocVisible: (state, action) => {
      const payload = action.payload;
      state.modalNguoiPhuThuocVisible = payload;
      if (!state.modalNguoiPhuThuocVisible) {
        state.dataNguoiPhuThuocModal = null;
      }
    },
    setDataNguoiPhuThuocModal: (state, action) => {
      const payload = action.payload;
      state.dataNguoiPhuThuocModal = payload;
    },

    setModalDanhSachChuyenDiVisible: (state, action) => {
      const payload = action.payload;
      state.modalDanhSachChuyenDiVisible = payload;
      if (!state.modalDanhSachChuyenDiVisible) {
        state.dataTripModal = null;
        state.dataModal = null;
      }
    },

    setDataSearch: (state, action) => {
      const payload = action.payload;
      state.dataSearch = payload;
    },
    setDataDanhSachChuyenDiSearch: (state, action) => {
      const payload = action.payload;
      state.dataDanhSachChuyenDiSearch = payload;
    },
    resetData: (state, action) => {
      state = initialState;
    },
    setRandom: (state, action) => {
      state.random = Math.random().toString(32);
    },
    setRandomUsers: (state, action) => {
      state.randomUsers = Math.random().toString(32);
    },
    setModalDeleteVisible: (state, action) => {
      const payload = action.payload;
      state.modalDeleteVisible = payload;
    },
    setModalThongKeVisible: (state, action) => {
      const payload = action.payload;
      state.modalThongKeVisible = payload;
    },
    setHieuChinhNhuanButModalVisible: (state, action) => {
      const payload = action.payload;
      state.hieuChinhNhuanButModalVisible = payload;
    },
    setHieuChinhNhuanButDataModal: (state, action) => {
      const payload = action.payload;
      state.hieuChinhNhuanButDataModal = payload;
    },
    setAppUserId: (state, action) => {
      const payload = action.payload;
      state.appUserId = payload;
    },
    
    setModalImportVisible: (state, action) => {
      const payload = action.payload;
      state.modalImportVisible = payload;
    },
    setDataImportModal: (state, action) => {
      const payload = action.payload;
      state.dataImportModal = payload;
    },  
  },
});
